body {
    margin: 0;
    padding: 0;
  }
  
  .card {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 300px;
    margin: 1rem;
    position: relative;
    width: 300px;
  }
  
  .card-1 {
    box-shadow: 0 1px 3px rgba(255, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 255, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  
  .card-2 {
    box-shadow: 0 3px 6px rgba(255, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 255, 0.23);
  }
  
  .card-3 {
    box-shadow: 0 10px 20px rgba(255, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 255, 0.23);
  }
  
  .card-4 {
    box-shadow: 0 14px 28px rgba(255, 0, 0, 0.25),
      0 10px 10px rgba(0, 0, 255, 0.22);
  }
  
  .card-5 {
    box-shadow: 0 19px 38px rgba(255, 0, 0, 0.3),
      0 15px 12px rgba(0, 0, 255, 0.22);
  }
  .body-wrapper {
    margin: 40px;
    font-family: sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #636262;
  }
  
  /** buttons to change view mode */
  .all-buttons {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .button {
    margin: 5px;
    padding: 5px 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .button.active {
    background-color: #636262;
    color: #ddd;
  }
  
  /** bar and bar progress of the different color */
  /* initial state */
  .gantt .bar-wrapper.blue .bar {
    fill: hsl(240, 50%, 57%);
  }
  .gantt .bar-wrapper.blue .bar-progress {
    fill: hsl(240, 100%, 77%);
  }
  .gantt .bar-wrapper.pink .bar {
    fill: hsl(347, 50%, 57%);
  }
  .gantt .bar-wrapper.pink .bar-progress {
    fill: hsl(347, 100%, 77%);
  }
  .gantt .bar-wrapper.purple .bar {
    fill: hsl(298, 50%, 57%);
  }
  .gantt .bar-wrapper.purple .bar-progress {
    fill: hsl(298, 100%, 77%);
  }
  
  /* hover state */
  .gantt .bar-wrapper.blue:hover .bar-progress {
    fill: hsl(240, 100%, 77%);
  }
  .gantt .bar-wrapper.pink:hover .bar-progress {
    fill: hsl(347, 100%, 77%);
  }
  .gantt .bar-wrapper.purple:hover .bar-progress {
    fill: hsl(298, 100%, 77%);
  }
  
  /* active state */
  .gantt .bar-wrapper.blue.active .bar {
    fill: hsl(240, 50%, 57%);
  }
  .gantt .bar-wrapper.blue.active .bar-progress {
    fill: hsl(240, 100%, 77%);
  }
  .gantt .bar-wrapper.pink.active .bar {
    fill: hsl(347, 50%, 57%);
  }
  .gantt .bar-wrapper.pink.active .bar-progress {
    fill: hsl(347, 100%, 77%);
  }
  .gantt .bar-wrapper.purple.active .bar {
    fill: hsl(298, 50%, 57%);
  }
  .gantt .bar-wrapper.purple.active .bar-progress {
    fill: hsl(298, 100%, 77%);
  }
  
  /** custom popup  */
  .details-container {
    width: 150px;
    background-color: #fff;
    color: #434141;
    border: 2px solid #ddd;
    border-radius: 5px;
  }
  
  .details-container > h5,
  .details-container > p {
    border-top: 2px solid #ddd;
    margin: 0;
    padding: 10px;
    font-size: 0.7rem;
  }
  
  .details-container > h5 {
    border-top: none;
  }
  